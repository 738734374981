<template>
    <div>
        <div v-if="pageType == 'form'" class="marginBottom20">
            <button class="btn btn-success" :disabled="!canSendStepOne" @click.prevent="submitActivity()">
                <template v-if="form.model.type != 'prework'"> Go to Questions </template>
                <template v-else> Go to Prework Materials </template>
                <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
            </button>
            <button class="btn btn-default" :disabled="!canSendStepOne" aria-label="Save and Exit" @click.prevent="saveAndExit">
                <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />
                Save and Exit
            </button>
        </div>

        <div v-if="pageType != 'confirmation'" class="pageTitle">
            <h1>Basic Settings</h1>
            <p class="col-md-6">
                This page allows you to create an activity by choosing the module it belongs to, selecting the activity type, and setting its name and
                description. If you have pre-made templates in the Library, you can also import an activity from there. Updates will appear in the panel below.
            </p>
        </div>

        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-12 col-sm-6">
                    <h2>Basic Settings</h2>
                </div>

                <div v-if="pageType == 'confirmation'" class="col-xs-12 col-sm-6 flex gap10" :class="{ justifyEnd: !isBelow768px }">
                    <button v-if="mode == 'edit'" class="btn btn-default margin0 flexInherit" exact @click.prevent="mode = 'view'">Cancel</button>
                    <button v-if="mode == 'edit'" class="btn btn-success margin0 flexInherit" @click.prevent="submitActivity()">Update</button>
                    <button
                        v-if="mode == 'view'"
                        class="btn btn-default margin0 flexInherit"
                        @click.prevent="
                            mode = 'edit';
                            init();
                        "
                    >
                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                    </button>
                </div>
            </template>

            <template #content>
                <div class="col-xs-12 col-md-6 marginBottom30">
                    <kr-select
                        v-if="pageType == 'form'"
                        display="Module<span class='important'>*</span> - select a module to add activity to:"
                        :form="form"
                        name="moduleUuid"
                        aria-label="module required - select a module to add activity to"
                        :items="moduleList"
                        @changed="changedModule"
                    >
                        <template #option="props">
                            <template v-for="(module, idx) in props.model" :key="'changedModule_' + idx">
                                <option :value="module.uuid">
                                    {{ module.name }}
                                    <template v-if="module.isArchived"> (Archived) </template>
                                </option>
                            </template>
                        </template>
                    </kr-select>
                    <div v-else class="form-group">
                        <label class="control-label">
                            module
                            <span class="important" aria-hidden="true">*</span>
                            <span class="visible-hidden">Required</span>
                        </label>
                        <p class="form-control-static">
                            {{ module.name }}
                        </p>
                    </div>
                </div>

                <div v-if="pageType == 'form'">
                    <div class="set-up-columns">
                        <div>
                            <kr-radio-multiple
                                display="Select an activity type"
                                :form="form"
                                name="type"
                                :items="activityTypes"
                                item_value_idx="value"
                                item_desc_idx="description"
                                :options="{
                                    required: true,
                                    radioButtonVertical: true,
                                }"
                                @changed="!activityObj ? changedActivityType() : openActivityTypeConfirmationModal()"
                            />
                        </div>

                        <div v-if="['prework', 'evaluation', 'peerEvaluationV2'].includes(form.model.type)">
                            <template v-if="['prework'].includes(form.model.type)">
                                <kr-radio-multiple
                                    name="others.preworkType"
                                    display="Prework Type"
                                    :form="form"
                                    :items="[
                                        {
                                            value: 'simple',
                                            description: 'Simple',
                                        },
                                        //{value:'adaptive',description:'Adaptive'},
                                    ]"
                                    item_value_idx="value"
                                    item_desc_idx="description"
                                    :options="{
                                        required: true,
                                        radioButtonVertical: true,
                                    }"
                                />
                            </template>
                            <template v-if="['evaluation'].includes(form.model.type)">
                                <label class="control-label">
                                    Students must evaluate
                                    <template>
                                        <span class="visible-hidden">Required</span>
                                        <span class="important" aria-hidden="true">*</span>
                                    </template>
                                </label>

                                <div class="newRadioField">
                                    <div class="checkboxField">
                                        <input id="evaluateTeammates" v-model="form.model.evaluationSettings.evaluateTeammates" type="checkbox" />
                                        <label for="evaluateTeammates" class="fw-normal paddingTop5"> Teammates </label>
                                    </div>
                                    <div class="checkboxField">
                                        <input id="evaluateThemselves" v-model="form.model.evaluationSettings.evaluateThemselves" type="checkbox" />
                                        <label for="evaluateThemselves" class="fw-normal paddingTop5"> Themselves </label>
                                    </div>
                                    <div class="checkboxField">
                                        <input id="evaluateTeam" v-model="form.model.evaluationSettings.evaluateTeam" type="checkbox" />
                                        <label for="evaluateTeam" class="fw-normal paddingTop5"> Team </label>
                                    </div>
                                    <div class="checkboxField">
                                        <input id="evaluateInstructor" v-model="form.model.evaluationSettings.evaluateInstructor" type="checkbox" />
                                        <label for="evaluateInstructor" class="fw-normal paddingTop5"> Instructor </label>
                                    </div>
                                </div>
                            </template>
                            <template v-if="['peerEvaluationV2'].includes(form.model.type)">
                                <kr-radio-multiple
                                    display="Select who to evaluate"
                                    :form="form"
                                    name="evaluationSettings.target"
                                    :items="targets"
                                    item_value_idx="value"
                                    item_desc_idx="description"
                                    :options="{
                                        required: true,
                                        radioButtonVertical: true,
                                    }"
                                    @changed="changedPeerEvaluationType()"
                                />
                            </template>
                        </div>

                        <div>
                            <kr-field
                                display="Name"
                                :form="form"
                                name="name"
                                :options="{
                                    placeholder: 'e.g., My activity',
                                    required: true,
                                }"
                            />

                            <kr-field
                                display="Description"
                                :form="form"
                                name="description"
                                :options="{
                                    type: 'text-area',
                                    rows: '5',
                                    placeholder: 'e.g., This one will not be easy',
                                    showError: false,
                                }"
                            />

                            <hr v-if="!['prework'].includes(form.model.type)" />

                            <div v-if="!['prework'].includes(form.model.type)">
                                <label class="control-label"> Question(s) Setup </label>
                                <br />
                                <button
                                    v-tooltip="templatesList.length == 0 ? 'No Template Available' : activityObj ? 'Please create a new activity' : ''"
                                    class="btn btn-outline-default marginLeft0"
                                    :class="{
                                        'hasTooltip disabled': activityObj || templatesList.length == 0,
                                    }"
                                    :aria-label="activityObj || templatesList.length == 0 ? 'Choose Templates disabled' : ''"
                                    @click.prevent="activityObj || templatesList.length == 0 ? '' : openChooseTemplateModal()"
                                >
                                    <span class="material-icons marginRight8" aria-hidden="true"> quiz </span>
                                    Choose Template
                                </button>
                            </div>

                            <div v-if="!selectedTemplate && templatesList.length > 0" class="alert alert-warning-new marginTop20">
                                <i class="fa fa-info-circle marginRight8" aria-hidden="true" />
                                Please note that you can only add the template at this step; once you move to the next step, it can't be reverted.
                            </div>

                            <div v-else-if="selectedTemplate && templatesList.length > 0" class="template-cards marginTop20">
                                <div class="card-body">
                                    <h3>{{ selectedTemplate.name }}</h3>
                                    <p v-if="selectedTemplate.description">{{ selectedTemplate.description }}</p>
                                </div>
                                <div class="card-footer flexSpaceBetween alignFlexEnd">
                                    <div>
                                        <p><b>Number of Questions:</b> {{ selectedTemplate.questionsCount }}</p>
                                        <p><b>Owner:</b> {{ selectedTemplate.owner }}</p>
                                    </div>
                                    <div>
                                        <button class="btn btn-danger" :disabled="activityObj" @click.prevent="selectedTemplate = ''">Remove</button>
                                    </div>
                                </div>
                            </div>

                            <div class="marginTop20 displayInlineBlock">
                                <button class="btn btn-success marginRight10 marginBottom10" :disabled="!canSendStepOne" @click.prevent="submitActivity()">
                                    <template v-if="form.model.type != 'prework'"> Go to Questions </template>
                                    <template v-else> Go to Prework Materials </template>
                                    <i class="fas fa-caret-right marginLeft8" aria-hidden="true" />
                                </button>

                                <button class="btn btn-default marginLeft0 marginBottom10" :disabled="!canSendStepOne" @click.prevent="saveAndExit">
                                    <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />
                                    Save and Exit
                                </button>
                            </div>
                        </div>

                        <div v-if="!['prework', 'evaluation', 'peerEvaluationV2'].includes(form.model.type)" class="create">
                            <div class="rightPanel">
                                <label class="control-label"> Selected Course Info </label>
                                <div class="content">
                                    <div>
                                        <label class="control-label"> Name </label>
                                        <p>
                                            {{ course.name }}
                                        </p>
                                    </div>
                                    <div>
                                        <label class="control-label"> Course Code </label>
                                        <p>
                                            {{ course.code }}
                                        </p>
                                    </div>
                                    <div>
                                        <label class="control-label"> Start From </label>
                                        <p>
                                            {{ convertToReadableDate(course.startDate, 'DD MMM YYYY, hh:mm a').date }}
                                            ({{ convertToReadableDate(course.startDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                        </p>
                                    </div>
                                    <div>
                                        <label class="control-label"> Ends On </label>
                                        <p>
                                            {{ convertToReadableDate(course.endDate, 'DD MMM YYYY, hh:mm a').date }}
                                            ({{ convertToReadableDate(course.endDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                        </p>
                                    </div>
                                    <div v-if="course.description">
                                        <label class="control-label"> Course Overview </label>
                                        <p class="whiteSpacePreLine" v-html="course.description"></p>
                                    </div>
                                </div>
                            </div>

                            <div class="rightPanel marginTop20">
                                <label class="control-label"> Selected Module Info </label>
                                <div class="content">
                                    <div>
                                        <label class="control-label"> Name </label>
                                        <p>
                                            {{ module.name }}
                                        </p>
                                    </div>
                                    <div>
                                        <label class="control-label"> Start From </label>
                                        <p>
                                            {{ convertToReadableDate(module.startDate, 'DD MMM YYYY, hh:mm a').date }}
                                            ({{ convertToReadableDate(module.startDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                        </p>
                                    </div>
                                    <div>
                                        <label class="control-label"> Ends On </label>
                                        <p>
                                            {{ convertToReadableDate(module.endDate, 'DD MMM YYYY, hh:mm a').date }}
                                            ({{ convertToReadableDate(module.endDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                        </p>
                                    </div>
                                    <div v-if="module.description">
                                        <label class="control-label"> Description </label>
                                        <p class="whiteSpacePreLine" v-html="module.description"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>

        <div
            id="changeActivityTypeModal"
            class="modal danger-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="changeActivityTypeModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="changeActivityTypeModal-title" class="modal-title">Change Activity Type</h2>
                    </div>
                    <div v-if="activityObj" class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" width="50px" alt="" role="presentation" />

                        <h1 class="marginTop40">Change Activity Type From</h1>

                        <h2
                            :aria-label="
                                (activityObj.type == 'iratntrat' ? 'IRAT and TRAT to ' : activityObj.type + ' to ') +
                                (form.model.type == 'iratntrat' ? 'IRAT and TRAT ' : form.model.type)
                            "
                        >
                            <span class="important" aria-hidden="true">
                                <template v-if="activityObj.type == 'prework'"> Prework </template>
                                <template v-if="activityObj.type == 'irat'"> IRAT </template>
                                <template v-if="activityObj.type == 'trat'"> TRAT </template>
                                <template v-if="activityObj.type == 'iratntrat'"> IRAT & TRAT </template>
                                <template v-if="activityObj.type == 'application'"> Application </template>
                                <template v-if="activityObj.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                                <template v-if="activityObj.type == 'evaluation'"> 360 Degree Evaluation </template>
                                <template v-if="activityObj.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                                <template v-if="activityObj.type == 'instructorEvaluation'"> Instructor Evaluation </template>
                                <template v-if="activityObj.type == 'survey'"> Survey </template>
                            </span>

                            <i class="fa-solid fa-arrow-right" aria-hidden="true"></i>

                            <span class="important" aria-hidden="true">
                                <template v-if="form.model.type == 'prework'"> Prework </template>
                                <template v-if="form.model.type == 'irat'"> IRAT </template>
                                <template v-if="form.model.type == 'trat'"> TRAT </template>
                                <template v-if="form.model.type == 'iratntrat'"> IRAT & TRAT </template>
                                <template v-if="form.model.type == 'application'"> Application </template>
                                <template v-if="form.model.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                                <template v-if="form.model.type == 'evaluation'"> 360 Degree Evaluation </template>
                                <template v-if="form.model.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                                <template v-if="form.model.type == 'instructorEvaluation'"> Instructor Evaluation </template>
                                <template v-if="form.model.type == 'survey'"> Survey </template>
                            </span>
                        </h2>

                        <br />

                        <p>Any questions that are not applicable</p>

                        <p>
                            with
                            <b>
                                <template v-if="form.model.type == 'prework'"> Prework </template>
                                <template v-if="form.model.type == 'irat'"> IRAT </template>
                                <template v-if="form.model.type == 'trat'"> TRAT </template>
                                <template v-if="form.model.type == 'iratntrat'"> IRAT & TRAT </template>
                                <template v-if="form.model.type == 'application'"> Application </template>
                                <template v-if="form.model.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                                <template v-if="form.model.type == 'evaluation'"> 360 Degree Evaluation </template>
                                <template v-if="form.model.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                                <template v-if="form.model.type == 'instructorEvaluation'"> Instructor Evaluation </template>
                                <template v-if="form.model.type == 'survey'"> Survey </template>
                            </b>
                            will be
                            <b class="important">permanently deleted</b>.
                        </p>

                        <br />

                        <p>
                            This action is
                            <b class="important">irreversible</b>.
                        </p>
                        <p>Do you wish to proceed with this irreversible action?</p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click="form.model.type = activityObj.type">No, cancel</button>

                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="changedActivityType()">Yes, Change It</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="chooseTemplateModal"
            class="modal primary-modal chooseTemplateModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="chooseTemplateModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="chooseTemplateModal-title" class="modal-title">Choose Template</h2>
                    </div>

                    <div class="modal-body">
                        <div>
                            <h2>List of Templates</h2>

                            <p>
                                <b>Showing {{ templatesList.length }} of {{ templatesList.length }}</b>
                            </p>

                            <div class="listing-template">
                                <template v-for="template in templatesList">
                                    <button
                                        class="template-cards marginTop10"
                                        :class="{
                                            selected: selectTemplate.uuid == template.uuid,
                                        }"
                                        @click.prevent="selectTemplate = template"
                                    >
                                        <div v-if="selectTemplate.uuid == template.uuid" class="selectedCard">
                                            <p>Selected</p>
                                        </div>
                                        <div class="card-body">
                                            <h3>{{ template.name }}</h3>
                                            <p v-if="template.description">{{ template.description }}</p>
                                        </div>
                                        <div class="card-footer">
                                            <div>
                                                <p><b>Number of Questions:</b> {{ template.questionsCount }}</p>
                                                <p><b>Owner:</b> {{ template.owner }}</p>
                                            </div>
                                        </div>
                                    </button>
                                </template>
                            </div>
                        </div>

                        <!-- <div class="col-xs-12 col-md-6">
                            <h2>Preview Template Selected</h2>

                            <iframe
                                v-if="form.model.type == 'irat'"
                                id="irat_iframe"
                                height="600px"
                                width="100%"
                                :src="`${APP_URL}/in/teachers/tests/${selectTemplate.uuid}/preview-rat?type=irat`"
                                title="Student App Preview"
                                @load="loadIframe()"
                            />

                            <iframe
                                v-else-if="form.model.type == 'trat'"
                                id="trat_iframe"
                                height="600px"
                                width="100%"
                                :src="`${APP_URL}/in/teachers/tests/${selectTemplate.uuid}/preview-rat?type=trat`"
                                title="Student App Preview"
                                @load="loadIframe()"
                            />

                            <iframe
                                v-else-if="form.model.type == 'application'"
                                id="application_iframe"
                                height="600px"
                                width="100%"
                                :src="`${APP_URL}/in/teachers/tests/${selectTemplate.uuid}/preview-rat`"
                                title="Student App Preview"
                                @load="loadIframe()"
                            />

                            <iframe
                                v-else-if="form.model.type == 'peerEvaluationV2'"
                                id="peerEvaluationV2_iframe"
                                height="600px"
                                width="100%"
                                :src="`${APP_URL}/in/teachers/tests/${selectTemplate.uuid}/preview-evaluation-v2`"
                                title="Student App Preview"
                                @load="loadIframe()"
                            />
                        </div> -->
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                        <button
                            v-tooltip="!selectTemplate ? 'Please select 1 template' : ''"
                            class="btn btn-success"
                            :class="{
                                'disabled hasToolTip': !selectTemplate,
                            }"
                            :aria-label="!selectTemplate ? 'Confirm Selected disabled' : ''"
                            @click.prevent="selectTemplate ? templateSelected(selectTemplate) : ''"
                        >
                            Confirm Selected
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../../../components/auth/auth';
import KrForm from '../../../../../../components/forms/form';
export default {
    components: {
        'cards-pagination': require(`./pagination.vue`).default,
    },
    props: ['activityObj', 'options'],
    data() {
        var auth = new KrAuth();
        let activityTypes = [
            {
                value: 'prework',
                description: 'Prework',
                _disabled: auth.user().account.version == 'lite',
            },
            { value: 'irat', description: 'IRAT only' },
            { value: 'trat', description: 'TRAT only' },
            { value: 'iratntrat', description: 'IRAT & TRAT' },
            {
                value: 'application',
                description: 'Application',
                _disabled: auth.user().account.version == 'lite',
            },
            {
                value: 'peerEvaluationV2',
                description: 'Peer Evaluation',
                _disabled: auth.user().account.version == 'lite',
                isNew: true,
            },
            {
                value: 'instructorEvaluation',
                description: 'Instructor Evaluation',
                _disabled: true,
                isComingSoon: true,
            },
            // {
            //     value: 'survey',
            //     description: 'Survey',
            //     _disabled: auth.user().account.version == 'lite',
            //     isNew: true,
            // },
            {
                value: 'michaelsenEvaluation',
                description: 'Peer Evaluation: Michaelsen’s Method',
                _disabled: auth.user().account.version == 'lite',
                isLegacy: true,
            },
            {
                value: 'evaluation',
                description: '360 Degree Evaluation',
                _disabled: auth.user().account.version == 'lite',
                isLegacy: true,
            },
        ];
        let targets = [
            { value: 'peers', description: 'Teammates Only' },
            { value: 'self', description: 'Self Only' },
            { value: 'all', description: 'Teammates and Self ' },
        ];
        /*if(process.env.VUE_APP_APP_ENV!='production'){
                activityTypes.push({value:'evaluation',description:'360 Degree Evaluation'});
            }*/
        return {
            auth: auth,
            moduleList: [],
            course: {},
            module: {},
            pageType: _.get(this.options, 'pageType', 'form'),
            mode: 'view',
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    courseUuid: '',
                    moduleUuid: '',
                    name: '',
                    type: 'default!:iratntrat',
                    description: '',
                    startDate: '',
                    endDate: '',
                    objectives: 'type!:array',
                    descriptionIsHTML: '',
                    others: 'type!:object', //json
                    evaluationSettings: 'type!:object', //json
                    templateUuid: '',
                },
                props: {
                    successMessage: false,
                },
                urls: {
                    post: 'activities',
                },
            }),
            activityTypes: activityTypes,
            targets: targets,
            selectTemplate: '',
            templatesList: [],
            APP_URL: process.env.VUE_APP_APP_URL,
            timeout: null,
            iframe: {
                loaded: false,
                hideBanner: false,
            },
            selectedTemplate: '',
        };
    },
    computed: {
        canSendStepOne() {
            if (this.form.model.courseUuid && this.form.model.moduleUuid && this.form.model.name) {
                return true;
            }
            return false;
        },
    },
    watch: {
        form: {
            handler: function () {
                /*FORCE*/
                if (!this.form.model.objectives) {
                    this.form.model.objectives = [];
                }
                if (this.form.model.objectives.length == 0) {
                    this.addObjective();
                }
            },
            deep: true,
        },
        options: {
            handler: function () {
                this.pageType = _.get(this.options, 'pageType', 'form');
            },
            deep: true,
        },
    },
    mounted() {
        Vue.nextTick(function () {
            $('#irat_iframe').contents().find('.fixed-banner-container').hide();
            $('#trat_iframe').contents().find('.fixed-banner-container').hide();
            $('#application_iframe').contents().find('.fixed-banner-container').hide();
            $('#peerEvaluationV2_iframe').contents().find('.fixed-banner-container').hide();
        });
    },
    created() {
        //set others
        this.form.model.others = {};
        this.form.model.evaluationSettings = {
            evaluateTeam: false,
            evaluateTeammates: false,
            evaluateInstructor: false,
            evaluateThemselves: false,
            target: 'peers',
            instructorsToEvaluate: [],
        };
        this.init();
    },
    methods: {
        init() {
            var that = this;
            this.form.model.descriptionIsHTML = false;
            let linked_course_id = _.get(this, '$route.params.course_id', false);
            let linked_module_id = _.get(this, '$route.params.module_id', false);
            if (this.$route.name == 'courses.modules.activities.create') {
                this.form.model.courseUuid = linked_course_id;
                this.form.model.moduleUuid = linked_module_id;
            }
            if (this.activityObj) {
                //already created
                this.form.setModel(_.get(this, 'activityObj', {}));
                this.form.model.others = this.activityObj.others;
                this.form.model.courseUuid = this.activityObj.course.uuid;
                this.form.model.moduleUuid = this.activityObj.module.uuid;
                if (Array.isArray(this.form.model.evaluationSettings) && ['peerEvaluationV2'].includes(this.form.model.type)) {
                    this.changedActivityType();
                }
            }
            var initRequirements = function () {
                axios.get('/courses/' + that.form.model.courseUuid).then(function (response) {
                    that.course = response.data.data;
                    that.moduleList = that.course.modules;
                    that.changedModule();
                    if (!that.form.model.objectives) {
                        that.form.model.objectives = [];
                    }
                    if (that.form.model.objectives.length == 0) {
                        that.addObjective();
                    }
                    if (that.$route.name == 'courses.modules.activities.create') {
                        let codeStr = '';
                        if (that.course.code) {
                            codeStr = ' (' + that.course.code + ')';
                        }
                        Events.fire('topbar_update', {
                            breadcrumb: [
                                { name: 'Home' },
                                { name: 'Courses', link: 'courses.index' },
                                {
                                    name: that.course.name + codeStr,
                                    link: 'courses.view',
                                    params: { id: that.course.uuid },
                                },
                                { name: 'New Activity' },
                            ],
                        });
                    }
                    that.component_done_loading = true;
                });
            }.bind(that);
            initRequirements();

            this.pullTemplatesList();
        },
        openActivityTypeConfirmationModal() {
            $('#changeActivityTypeModal').modal('show');
        },
        changedActivityType() {
            if (this.form.model.type == 'prework') {
                this.form.model.others.preworkType = 'simple';
            } else if (this.form.model.type == 'application') {
                this.form.model.others.applicationType = 'team';
                this.form.model.others.isApplicationGraded = true;
            } else if (this.form.model.type == 'evaluation') {
                this.form.model.evaluationSettings.evaluateTeammates = true;
                this.form.model.evaluationSettings.evaluateThemselves = false;
                this.form.model.evaluationSettings.evaluateTeam = false;
                this.form.model.evaluationSettings.evaluateInstructor = false;
                this.form.model.evaluationSettings.instructorsToEvaluate = [this.course.owner.uuid];
            } else if (this.form.model.type == 'michaelsenEvaluation') {
                this.form.model.evaluationSettings.evaluateTeammates = true;
                this.form.model.evaluationSettings.evaluateThemselves = false;
                this.form.model.evaluationSettings.evaluateTeam = false;
                this.form.model.evaluationSettings.evaluateInstructor = false;
                this.form.model.evaluationSettings.instructorsToEvaluate = [];
            } else if (this.form.model.type == 'peerEvaluationV2') {
                this.form.model.evaluationSettings = {};
                this.form.model.evaluationSettings.target = 'peers';
                this.form.model.evaluationSettings.evaluateTeammates = true;
                this.form.model.evaluationSettings.evaluateThemselves = false;
                this.form.model.evaluationSettings.instructorsToEvaluate = [];
            } else if (this.form.model.type == 'instructorEvaluation') {
                this.form.model.evaluationSettings.evaluateTeammates = false;
                this.form.model.evaluationSettings.evaluateThemselves = false;
                this.form.model.evaluationSettings.evaluateTeam = false;
                this.form.model.evaluationSettings.evaluateInstructor = true;
                this.form.model.evaluationSettings.instructorsToEvaluate = [this.course.owner.uuid];
            } else {
                //this.form.model.others = {};
                //this.form.model.evaluationSettings = {};
            }

            this.pullTemplatesList();
        },
        changedPeerEvaluationType() {
            if (this.form.model.evaluationSettings.target == 'peers') {
                this.form.model.evaluationSettings.evaluateTeammates = true;
                this.form.model.evaluationSettings.evaluateThemselves = false;
            } else if (this.form.model.evaluationSettings.target == 'self') {
                this.form.model.evaluationSettings.evaluateTeammates = false;
                this.form.model.evaluationSettings.evaluateThemselves = true;
            } else if (this.form.model.evaluationSettings.target == 'all') {
                this.form.model.evaluationSettings.evaluateTeammates = true;
                this.form.model.evaluationSettings.evaluateThemselves = true;
            }
        },
        changedModule() {
            let module_id = this.form.model.moduleUuid; // The value of the selected option
            if (module_id) {
                this.module = _.find(this.moduleList, function (o) {
                    return o.uuid == module_id;
                });
            }
        },
        addObjective() {
            this.form.model.objectives.push('');
        },
        deleteObjective(idx) {
            this.form.model.objectives.splice(idx, 1);
        },
        saveAndExit() {
            this.submitActivity(true);
        },
        submitActivity(exitOnSave = false) {
            var that = this;
            that.form.model.descriptionIsHTML = false;
            that.form.model.templateUuid = that.selectedTemplate.uuid;
            let url = '/activities';

            if (!this.activityObj) {
                this.form.post(url).then(function (response) {
                    if (!response.krFormError) {
                        that.$router.push({
                            name: 'courses.modules.activities.continue',
                            params: { id: response.data.data.uuid },
                        });
                        that.form.setModel(response.data.data);
                        Vue.nextTick(function () {
                            if (exitOnSave === false) {
                                that.$emit('activity-updated', response.data.data);
                                that.$emit('next-step');
                            } else {
                                that.$emit('save-and-exit', response.data.data);
                            }
                        });
                    }
                });
            } else {
                url = '/activities/' + this.activityObj.uuid;
                this.form.put(url).then(function (response) {
                    if (!response.krFormError) {
                        that.$emit('activity-updated', response.data.data);
                        if (exitOnSave === true) {
                            Vue.nextTick(function () {
                                that.$emit('save-and-exit');
                            });
                        } else {
                            if (that.pageType != 'form') {
                                that.mode = 'view';
                            } else {
                                Vue.nextTick(function () {
                                    that.$emit('next-step');
                                });
                            }
                        }
                    }
                });
            }
        },
        openChooseTemplateModal() {
            if (!this.selectedTemplate) {
                this.selectTemplate = '';
            }
            $('.chooseTemplateModal').modal('show');
        },
        pullTemplatesList() {
            var that = this;
            axios.post(`templates/list`, { activityType: this.form.model.type }).then(function (response) {
                // console.log(response);
                that.templatesList = response.data.data;
            });
        },
        loadIframe() {
            this.iframe.loaded = true;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.hideDiv.bind(this), 100);
        },
        hideDiv() {
            if (this.form.model.type == 'iratntrat') {
                var splitArray = this.form.model.type.split('n');
                for (var i = 0; i < splitArray.length; i++) {
                    var iframe = $('#' + splitArray[i] + '_iframe').contents();

                    iframe.find('body').removeClass('modal-open');
                    iframe.find('.fixed-banner-container').hide();
                    iframe.find('.col-md-3.left_col.no-print').hide();
                    iframe.find('footer').hide();
                    iframe.find('#launcher').hide();
                    iframe.find('.statusBar').hide();
                    iframe.find('.right-container').attr('style', 'padding-top: 20px !important');
                    iframe.find('.statusBar ~ main.main').attr('style', 'padding:0; width:100%');
                    iframe.find('.test_content').attr('style', 'padding: 20px');
                }
            } else {
                var iframe = $('#' + this.form.model.type + '_iframe').contents();

                iframe.find('body').removeClass('modal-open');
                iframe.find('.fixed-banner-container').hide();
                iframe.find('.col-md-3.left_col.no-print').hide();
                iframe.find('footer').hide();
                iframe.find('#launcher').hide();
                iframe.find('.statusBar').hide();
                iframe.find('.right-container').attr('style', 'padding-top: 20px !important');
                iframe.find('.statusBar ~ main.main').attr('style', 'padding:0; width:100%');
                iframe.find('.test_content').attr('style', 'padding: 20px');
            }
        },
        templateSelected(selectTemplate) {
            this.selectedTemplate = selectTemplate;
            this.closeAllModal();
        },
    },
};
</script>
