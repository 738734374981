const lti1p3AccountsList = [
    '08299f88-6223-49aa-84e7-1958d703de6e',
    'd1505db9-f490-4d30-81c8-0501410aecee',
    '8171c7da-3725-4f43-b871-dc52272610ec',
    '937caf1e-aa76-4f46-a391-86aef3941638',
    '58be2094-6f74-4087-bba5-ace0b7e3b651',
    '8171c7da-3725-4f43-b871-dc52272610ec',
    'd89d09f5-ec57-47af-8c4b-dd4291e3100d',
    '04dee48c-19f0-43b2-87ed-5efb86eed881',
    '8730eab5-a205-4cb3-85d9-9c10dcf7a1f5',
    '8726d872-408c-4932-8e78-809ccd1a9a1a',
    'd6d49ef0-5a7c-4ca9-b2c5-5f9888ffd007',
    '6d4a2300-db56-41eb-9eaa-1bcab3afdf4b',
    '58be2094-6f74-4087-bba5-ace0b7e3b651',
    '8eb35faf-12af-42fd-b7f3-833b4ae2a9f8',
    '31c1b351-2676-4ea3-aa12-aae13dcaff01',
    '2a815d95-36dd-4055-961a-d3e841f0fd78',
    '505898f6-3e79-459e-afe0-256677831495',
    'c10dc863-41be-49bd-a5e5-ea07408416f1',
    '9b05cd86-7783-4b23-8447-e90684831259',
    '93c301ae-d1af-46ff-a411-4047a1a62541',
    '8fab9fef-2106-49f9-ae3a-3e8741d6d709',
    '8726d872-408c-4932-8e78-809ccd1a9a1a',
];

export { lti1p3AccountsList };
