<template>
    <div>
        <div class="question marginBottom10">
            <label class="control-label" :class="{ whiteText: isNightMode }">Question Stem</label>
            <kr-math :input="question.question" :safe="!question.questionIsHTML"></kr-math>
            <!-- list with no answer = array in question.amswers and check if answer.uuid==null-->
            <template v-if="question.attachments && question.attachments.length != 0">
                <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true"></i>Question Attachment(s)</div>
                <div class="textLink">
                    <div v-for="(file, idx) in question.attachments" :key="idx" class="flex">
                        <i class="fa fa-file-image-o marginRight8" aria-hidden="true"></i>
                        <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                    </div>
                </div>
            </template>
        </div>

        <div v-if="question.displayKeywords" class="marginTop20 dropShadow borderBlack bg-plain-white padding10 width100 borderRadiu8">
            <label class="control-label">Keyword(s):</label>
            <ul v-if="question.tags.length != 0" class="listStyleNone flex gap10 padding0 keywordChips">
                <template v-for="(tag, idx) in question.tags" :key="idx">
                    <li class="statusTag statusTag-bs-dark">{{ tag }}</li>
                </template>
            </ul>
            <template v-if="question.tags.length == 0"> - </template>
        </div>

        <template v-if="showRealtimeToggle">
            <template v-if="[null, 'cancelled', 'preparing'].includes(question.eGallery.status)">
                <template v-if="question.displayAnswer && question.answers.length != 0">
                    <div class="flexRight width100 marginTop20">
                        <div class="checkboxField align-items">
                            <label for="selectAllAnsForEGalleryWalk" class="control-label marginBottom0 marginRight8"> Select all for e-Gallery Walk </label>
                            <input id="selectAllAnsForEGalleryWalk" type="checkbox" :checked="isAllVotingToggled" @click="toggleAllVoting($event)" />
                        </div>
                    </div>
                </template>
            </template>
        </template>

        <div class="cr-display-columns" :style="{ 'grid-template-columns': `repeat(${selectedColumn}, 1fr)` }">
            <template v-if="showRealtimeToggle">
                <template v-if="[null, 'cancelled', 'preparing'].includes(question.eGallery.status)">
                    <template v-for="(answer, answer_idx) in question.answers" :key="answer_idx">
                        <template v-if="question.displayAnswer">
                            <div
                                class="borderBlack width100 padding0"
                                :class="{
                                    'add-blueBg': question.answers[answer_idx].isSelected === true,
                                }"
                            >
                                <div class="padding10 flex gap10 flexSpaceBetween">
                                    <div class="flex gap10 align-items">
                                        <div class="dropdown">
                                            <button
                                                v-tooltip="testObj.others.applicationType == 'team' ? answer.team.name : answer.student.fullname"
                                                class="btn btn-outline-default margin0 flexInherit"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <template v-if="testObj.others.applicationType == 'team'">
                                                    <template v-if="answer.team.name.length > 10"> {{ truncate(answer.team.name, 10) }} </template>
                                                    <template v-else> {{ answer.team.name }} </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="answer.student.fullname.length > 10">
                                                        {{ truncate(answer.student.fullname, 10) }}
                                                    </template>
                                                    <template v-else> {{ answer.student.fullname }} </template>
                                                </template>
                                                <i
                                                    v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                    class="far fa-comment-dots marginLeft8"
                                                ></i>
                                            </button>
                                            <div class="dropdown-menu dropdown-disucssion padding10">
                                                <div>
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-comments marginRight5 greyText"></i>
                                                        <label class="control-label fs-14px margin0"> Elaboration </label>
                                                    </div>
                                                    <div v-if="answer.comment">
                                                        <div class="wordBreakWord whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</div>

                                                        <button
                                                            class="btn btn-link marginLeft0 marginTop10 padding0"
                                                            @click="
                                                                testObj.others.applicationType == 'team'
                                                                    ? openModelInfoModal(answer)
                                                                    : openModelInfoModal(answer, 'student')
                                                            "
                                                        >
                                                            View More
                                                        </button>
                                                    </div>
                                                    <div v-else>-</div>
                                                </div>
                                                <div class="marginTop20">
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-paperclip marginRight5 greyText"></i>
                                                        <label class="control-label fs-14px margin0">Attachment(s)</label>
                                                    </div>
                                                    <div>
                                                        <template v-if="answer.attachments && answer.attachments.length != 0">
                                                            <div v-for="(file, idx) in answer.attachments" :key="idx" class="flex maxHeight100px">
                                                                <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                                    file.filename
                                                                }}</a>
                                                            </div>

                                                            <button
                                                                class="btn btn-link marginLeft0 marginTop10 padding0"
                                                                @click="
                                                                    testObj.others.applicationType == 'team'
                                                                        ? openModelInfoModal(answer)
                                                                        : openModelInfoModal(answer, 'student')
                                                                "
                                                            >
                                                                View More
                                                            </button>
                                                        </template>
                                                        <template v-else> - </template>
                                                    </div>
                                                </div>
                                                <div v-if="testObj.others.applicationType == 'team'" class="marginTop20">
                                                    <div class="flexOnly marginBottom10 align-items">
                                                        <i class="fas fa-users marginRight8 greyText"></i>
                                                        <label class="control-label fs-14px margin0">Team Members</label>
                                                    </div>
                                                    <div class="maxHeight100px">
                                                        <ol class="margin0">
                                                            <li v-for="(student, idx) in answer.team.members" :key="idx">
                                                                {{ student.displayName }}
                                                            </li>
                                                        </ol>
                                                    </div>

                                                    <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openModelInfoModal(answer)">
                                                        View More
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess">
                                            Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                    <div v-if="answer.uuid" class="flex gap10 align-items">
                                        <div
                                            class="checkboxField"
                                            :class="{
                                                'align-items': !(
                                                    question.answers[answer_idx].isGoodAnswer == 1 || question.answers[answer_idx].isGoodAnswer == true
                                                ),
                                            }"
                                        >
                                            <input
                                                :id="'selectAnswerForEGallerryWalk-' + answer.uuid"
                                                v-model="question.answers[answer_idx].isSelected"
                                                v-tooltip="{
                                                    content: generateToolTip(question.answers[answer_idx].isSelected),
                                                }"
                                                type="checkbox"
                                                :disabled="
                                                    hasWritePrivilege(testObj.course) == -1 ||
                                                    question.answers[answer_idx].isGoodAnswer == 1 ||
                                                    question.answers[answer_idx].isGoodAnswer == true
                                                        ? true
                                                        : undefined
                                                "
                                            />
                                            <label
                                                :for="'selectAnswerForEGallerryWalk-' + answer.uuid"
                                                class="d-block margin0 marginLeft8"
                                                :class="{
                                                    'fontGrey disabled':
                                                        hasWritePrivilege(testObj.course) == -1 ||
                                                        question.answers[answer_idx].isGoodAnswer == 1 ||
                                                        question.answers[answer_idx].isGoodAnswer == true
                                                            ? true
                                                            : undefined,
                                                }"
                                            >
                                                <b>Select for e-Gallery Walk</b>
                                                <br />
                                                <p v-if="question.answers[answer_idx].isGoodAnswer == 1 || question.answers[answer_idx].isGoodAnswer == true">
                                                    (Answer is set as good answer)
                                                </p>
                                            </label>
                                        </div>

                                        <div class="thumb-up-toggle">
                                            <input
                                                :id="answer_idx + '-toggle-good-answer'"
                                                v-model="question.answers[answer_idx].isGoodAnswer"
                                                v-tooltip="{
                                                    content: answer.isGoodAnswer ? 'Remove from good answers' : 'Select as good answer',
                                                }"
                                                :true-value="1"
                                                :false-value="0"
                                                type="checkbox"
                                                :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                                @change="toggleIsGoodAnswer(question.answers[answer_idx])"
                                            />
                                            <label :for="answer_idx + '-toggle-good-answer'" class="control-label marginBottom0 marginLeft8">
                                                Set As Good Answer
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="padding10 borderTop1pxGrey">
                                    <label class="control-label">
                                        <template v-if="testObj.others.applicationType == 'team'">
                                            {{ answer.team.name }}
                                        </template>
                                        <template v-else>
                                            {{ answer.student.fullname }}
                                        </template>
                                        Answer
                                    </label>
                                    <template v-if="answer.answer != null">
                                        <div class="wordBreakWord whiteSpacePreWrap" v-html="truncate(highlightAnswerKeywords(answer.answer), 400)" />

                                        <button
                                            class="btn btn-link marginLeft0 marginTop10 padding0"
                                            @click="openQuestionModal(highlightAnswerKeywords(answer.answer))"
                                        >
                                            View More
                                        </button>
                                    </template>
                                    <p v-else class="greyText">
                                        <i>
                                            <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                            <template v-else> Student </template>
                                            Has Not Answered Yet.
                                        </i>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </template>
                </template>
                <template
                    v-if="
                        ['paused', 'ongoing', 'ended', 'released'].includes(question.eGallery.status) &&
                        ['ongoing', 'completed', 'ended', 'paused'].includes(testObj.status)
                    "
                >
                    <template v-for="(answer, answer_idx) in question.answers" :key="answer_idx">
                        <template v-if="answer.isSelected">
                            <div v-if="answer.answer != null" class="borderBlack width100 padding0">
                                <div class="padding10 flex gap10 flexSpaceBetween align-items">
                                    <div class="flex gap10 align-items">
                                        <div class="dropdown">
                                            <button
                                                v-tooltip="testObj.others.applicationType == 'team' ? answer.team.name : answer.student.fullname"
                                                class="btn btn-outline-default margin0 flexInherit"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <template v-if="testObj.others.applicationType == 'team'">
                                                    <template v-if="answer.team.name.length > 10"> {{ truncate(answer.team.name, 10) }} </template>
                                                    <template v-else> {{ answer.team.name }} </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="answer.student.fullname.length > 10">
                                                        {{ truncate(answer.student.fullname, 10) }}
                                                    </template>
                                                    <template v-else> {{ answer.student.fullname }} </template>
                                                </template>
                                                <i
                                                    v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                    class="far fa-comment-dots marginLeft8"
                                                ></i>
                                            </button>
                                            <div class="dropdown-menu dropdown-disucssion padding10">
                                                <div>
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-comments marginRight5 greyText"></i>
                                                        <label class="control-label fs-14px margin0"> Elaboration </label>
                                                    </div>
                                                    <div v-if="answer.comment">
                                                        <div class="wordBreakWord whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</div>

                                                        <button
                                                            class="btn btn-link marginLeft0 marginTop10 padding0"
                                                            @click="
                                                                testObj.others.applicationType == 'team'
                                                                    ? openModelInfoModal(answer)
                                                                    : openModelInfoModal(answer, 'student')
                                                            "
                                                        >
                                                            View More
                                                        </button>
                                                    </div>
                                                    <div v-else>-</div>
                                                </div>
                                                <div class="marginTop20">
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-paperclip marginRight5 greyText"></i>
                                                        <label class="control-label fs-14px margin0">Attachment(s)</label>
                                                    </div>
                                                    <div>
                                                        <template v-if="answer.attachments && answer.attachments.length != 0">
                                                            <div v-for="(file, idx) in answer.attachments" :key="idx" class="flex maxHeight100px">
                                                                <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                                    file.filename
                                                                }}</a>
                                                            </div>

                                                            <button
                                                                class="btn btn-link marginLeft0 marginTop10 padding0"
                                                                @click="
                                                                    testObj.others.applicationType == 'team'
                                                                        ? openModelInfoModal(answer)
                                                                        : openModelInfoModal(answer, 'student')
                                                                "
                                                            >
                                                                View More
                                                            </button>
                                                        </template>
                                                        <template v-else> - </template>
                                                    </div>
                                                </div>
                                                <div v-if="testObj.others.applicationType == 'team'" class="marginTop20">
                                                    <div class="flexOnly marginBottom10 align-items">
                                                        <i class="fas fa-users marginRight8 greyText"></i>
                                                        <label class="control-label fs-14px margin0">Team Members</label>
                                                    </div>
                                                    <div class="maxHeight100px">
                                                        <ol class="margin0">
                                                            <li v-for="(student, idx) in answer.team.members" :key="idx">
                                                                {{ student.displayName }}
                                                            </li>
                                                        </ol>
                                                    </div>
                                                    <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openModelInfoModal(answer)">
                                                        View More
                                                    </button>
                                                </div>

                                                <template v-else>
                                                    <div class="flexOnly marginBottom10 align-items">
                                                        <i class="fas fa-user marginRight8 greyText"></i>
                                                        <label class="control-label fs-14px margin0">Student Fullname</label>
                                                    </div>
                                                    <p>
                                                        {{ answer.student.fullname }}
                                                    </p>
                                                </template>
                                            </div>
                                        </div>

                                        <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess">
                                            Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                    <label v-if="question.displayVotes" class="fs-20px">
                                        <b>
                                            {{ answer.votes.length }}
                                            <template v-if="answer.votes.length < 2"> Vote </template>
                                            <template v-else> Votes </template>
                                        </b>
                                    </label>
                                </div>

                                <div v-if="question.displayAnswer" class="padding10 borderTop1pxGrey">
                                    <label class="control-label">
                                        <template v-if="testObj.others.applicationType == 'team'">
                                            {{ answer.team.name }}
                                        </template>
                                        <template v-else>
                                            {{ answer.student.fullname }}
                                        </template>
                                        Answer
                                    </label>

                                    <template v-if="answer.answer != null">
                                        <div class="wordBreakWord whiteSpacePreWrap" v-html="truncate(highlightAnswerKeywords(answer.answer), 400)" />

                                        <button
                                            class="btn btn-link marginLeft0 marginTop10 padding0"
                                            @click="openQuestionModal(highlightAnswerKeywords(answer.answer))"
                                        >
                                            View More
                                        </button>
                                    </template>
                                    <p v-else class="greyText">
                                        <i>
                                            <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                            <template v-else> Student </template>
                                            Has Not Answered Yet.
                                        </i>
                                    </p>
                                </div>

                                <div v-if="question.displayComments" class="flexRight padding10">
                                    <button
                                        v-tooltip="answer.comments.length == 0 ? 'No Comment' : ''"
                                        class="btn margin0 flexInherit"
                                        :class="{ 'greyText hasTooltip': answer.comments.length == 0, 'btn-link ': answer.comments.length > 0 }"
                                        :aria-disabled="answer.comments.length == 0"
                                        @click="answer.comments.length > 0 ? openCommentsModal(answer) : ''"
                                    >
                                        {{ answer.comments.length }}
                                        <template v-if="answer.comments.length < 2"> Comment </template>
                                        <template v-else> Comments </template>
                                    </button>
                                </div>

                                <div v-if="question.displayVotes" class="padding10">
                                    <label :for="'progressbar-' + answer_idx" class="fs-20px width100">
                                        {{ percentVoted(answer_idx) }}% of {{ testObj.others.applicationType == 'team' ? 'team/s' : 'student/s' }} voted
                                    </label>

                                    <progress
                                        :id="'progressbar-' + answer_idx"
                                        role="progressbar"
                                        :value="percentVoted(answer_idx)"
                                        aria-valuemin="0"
                                        :aria-valuenow="percentVoted(answer_idx)"
                                        aria-valuemax="100"
                                        max="100"
                                        class="progressbar height2rem width100"
                                    />
                                </div>

                                <div v-if="answer.votes.length != 0" class="width100 padding10">
                                    <label class="control-label fs-20px">
                                        {{ answer.votes.length }}
                                        <template v-if="answer.votes.length <= 1"> Vote </template>
                                        <template v-else> Votes </template>
                                    </label>

                                    <div class="flex gap10">
                                        <div v-for="(model, model_idx) in answer.votes" :key="model_idx" class="dropdown">
                                            <button
                                                v-tooltip="testObj.others.applicationType == 'team' ? model.name : model.fullname"
                                                class="btn btn-outline-default margin0 flexInherit"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                {{ testObj.others.applicationType == 'team' ? truncate(model.name) : truncate(model.fullname) }}
                                            </button>
                                            <div class="dropdown-menu dropdown-disucssion padding10">
                                                <div v-if="testObj.others.applicationType == 'team'">
                                                    <div class="flexOnly marginBottom10 align-items">
                                                        <i class="fas fa-users marginRight8 greyText"></i>
                                                        <label class="control-label fs-14px margin0">Team Members</label>
                                                    </div>
                                                    <div class="maxHeight100px">
                                                        <ol class="margin0">
                                                            <li v-for="(student, idx) in model.members" :key="idx">
                                                                {{ student.displayName }}
                                                            </li>
                                                        </ol>
                                                    </div>

                                                    <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openTeamInfoModal(model)">
                                                        View More
                                                    </button>
                                                </div>
                                                <template v-else>
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-user marginRight8 greyText"></i>
                                                        <label class="control-label fs-14px margin0"> Student Fullname </label>
                                                        <p>{{ model.fullname }}</p>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </template>
            </template>
            <template v-else>
                <template v-for="(answer, answer_idx) in question.answers" :key="answer_idx">
                    <div class="borderBlack width100 padding0">
                        <div class="padding10 flex gap10 flexSpaceBetween">
                            <div class="flex gap10 align-items">
                                <div class="dropdown">
                                    <button
                                        v-tooltip="testObj.others.applicationType == 'team' ? answer.team.name : answer.student.fullname"
                                        class="btn btn-outline-default"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <template v-if="testObj.others.applicationType == 'team'">
                                            <template v-if="answer.team.name.length > 10"> {{ truncate(answer.team.name, 10) }} </template>
                                            <template v-else> {{ answer.team.name }} </template>
                                        </template>
                                        <template v-else>
                                            <template v-if="answer.student.fullname.length > 10"> {{ truncate(aanswer.student.fullname, 10) }} </template>
                                            <template v-else> {{ answer.student.fullname }} </template>
                                        </template>
                                        <i
                                            v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                            class="far fa-comment-dots marginLeft8"
                                        ></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-disucssion padding10">
                                        <div>
                                            <div class="flexOnly marginBottom5 align-items">
                                                <i class="fas fa-comments marginRight5 greyText"></i>
                                                <label class="control-label fs-14px margin0"> Elaboration </label>
                                            </div>
                                            <div v-if="answer.comment">
                                                <div class="wordBreakWord whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</div>

                                                <button
                                                    class="btn btn-link marginLeft0 marginTop10 padding0"
                                                    @click="
                                                        testObj.others.applicationType == 'team'
                                                            ? openModelInfoModal(answer)
                                                            : openModelInfoModal(answer, 'student')
                                                    "
                                                >
                                                    View More 3
                                                </button>
                                            </div>
                                            <div v-else>-</div>
                                        </div>
                                        <div class="marginTop20">
                                            <div class="flexOnly marginBottom5 align-items">
                                                <i class="fas fa-paperclip marginRight5 greyText"></i>
                                                <label class="control-label fs-14px margin0">Attachment(s)</label>
                                            </div>
                                            <div>
                                                <template v-if="answer.attachments && answer.attachments.length != 0">
                                                    <div v-for="(file, idx) in answer.attachments" :key="idx" class="flex maxHeight100px">
                                                        <span class="marginRight5">{{ idx + 1 }}.</span>
                                                        <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                            file.filename
                                                        }}</a>
                                                    </div>

                                                    <button
                                                        class="btn btn-link marginLeft0 marginTop10 padding0"
                                                        @click="
                                                            testObj.others.applicationType == 'team'
                                                                ? openModelInfoModal(answer)
                                                                : openModelInfoModal(answer, 'student')
                                                        "
                                                    >
                                                        View More
                                                    </button>
                                                </template>
                                                <template v-else> - </template>
                                            </div>
                                        </div>
                                        <div v-if="testObj.others.applicationType == 'team'" class="marginTop20">
                                            <div class="flexOnly marginBottom10 align-items">
                                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true"></i>
                                                <label class="control-label fs-14px margin0">Team Members</label>
                                            </div>
                                            <div class="maxHeight100px">
                                                <ol class="margin0">
                                                    <li v-for="(student, idx) in answer.team.members" :key="idx">
                                                        {{ student.displayName }}
                                                    </li>
                                                </ol>
                                            </div>

                                            <button class="btn btn-link marginLeft0 marginTop10 padding0" @click="openModelInfoModal(answer)">View More</button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess">
                                    Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true"></i>
                                </div>
                            </div>

                            <div v-if="answer.uuid" class="thumb-up-toggle">
                                <input
                                    :id="answer_idx + '-toggle-good-answer'"
                                    v-model="question.answers[answer_idx].isGoodAnswer"
                                    v-tooltip="{
                                        content: answer.isGoodAnswer ? 'Remove from good answers' : 'Select as good answer',
                                    }"
                                    type="checkbox"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    @change="toggleIsGoodAnswer(question.answers[answer_idx])"
                                />
                                <label :for="answer_idx + '-toggle-good-answer'" class="control-label marginLeft8"> Set As Good Answer </label>
                            </div>
                        </div>
                        <div
                            class="padding10 borderTop1pxGrey"
                            :class="{
                                'gridDiscussion gap10': answer.uuid && !showRealtimeToggle && testObj.others.isApplicationGraded && !isMobileView,
                            }"
                        >
                            <div :class="{ minusWidth179px: !isMobileView }">
                                <label class="control-label">
                                    <template v-if="testObj.others.applicationType == 'team'">
                                        {{ answer.team.name }}
                                    </template>
                                    <template v-else>
                                        {{ answer.student.fullname }}
                                    </template>
                                    Answer
                                </label>

                                <template v-if="answer.answer != null">
                                    <div class="wordBreakWord whiteSpacePreWrap" v-html="truncate(highlightAnswerKeywords(answer.answer), 400)" />

                                    <button
                                        class="btn btn-link marginLeft0 marginTop10 padding0"
                                        @click="openQuestionModal(highlightAnswerKeywords(answer.answer))"
                                    >
                                        View More
                                    </button>
                                </template>
                                <p v-else class="greyText">
                                    <i>
                                        <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                        <template v-else> Student </template>
                                        Has Not Answered Yet.
                                    </i>
                                </p>
                            </div>
                            <div v-if="answer.uuid && !showRealtimeToggle && testObj.others.isApplicationGraded" :class="{ marginTop20: isMobileView }">
                                <div>
                                    <label :for="'grades_' + answer_idx" class="control-label">
                                        Grade
                                        <i v-if="answer._gradeSaved" class="fa fa-check-circle dateCircle marginRight10"></i>
                                    </label>
                                    <div class="input-group width148px">
                                        <div class="numberInput width100">
                                            <input
                                                :id="'grades_' + answer_idx"
                                                type="number"
                                                class="form-control"
                                                placeholder="Enter Points"
                                                min="0"
                                                :max="question.score"
                                                step=".25"
                                                :value="answer.applicationGrade"
                                                @keyup="changePoints($event, answer_idx)"
                                                @keydown="answer._gradeSaved = false"
                                                @blur="saveGrade(answer_idx)"
                                            />
                                        </div>
                                        <div class="input-group-addon">/{{ question.score }}</div>
                                    </div>
                                </div>
                                <div class="marginTop20">
                                    <label :for="'feedback_' + answer_idx" class="control-label">
                                        Feedback
                                        <i v-if="answer._narrativeSaved" class="fa fa-check-circle dateCircle marginRight10"></i>
                                    </label>
                                    <div class="form-group">
                                        <textarea
                                            :id="'feedback_' + answer_idx"
                                            ref="textarea"
                                            class="form-control"
                                            placeholder="Enter Feedback here..."
                                            :value="answer.narrativeFeedback"
                                            aria-label="Feedback"
                                            rows="5"
                                            @keyup="changeNarrativeFeedback($event, answer_idx)"
                                            @keydown="answer._narrativeSaved = false"
                                            @blur="
                                                changeNarrativeFeedback($event, answer_idx);
                                                saveNarrativeFeedback(answer_idx);
                                            "
                                            @input="textareaResize"
                                        >
                                        </textarea>
                                        <span :id="answer_idx + '-count-words'" class="limitText" aria-live="assertive"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <div
            id="eGalleryTeamInfoModal"
            class="modal default-modal"
            :class="{ 'dark-modal': isNightMode }"
            tabindex="-1"
            role="dialog"
            aria-labelledby="eGalleryTeamInfoModal"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="eGalleryTeamInfoModal" class="modal-title">View {{ previewTeam.name }}</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12">
                            <ol v-for="(student, idx) in previewTeam.members" :key="idx">
                                <li>{{ student }}</li>
                            </ol>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryAttachmentsModal' + question.uuid"
            class="modal default-modal"
            :class="{ 'dark-modal': isNightMode }"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryAttachmentsModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryAttachmentsModal' + question.uuid + '-title'" class="modal-title">Attachments</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 col-md-6">
                            <div v-if="previewAttachments && previewAttachments.length != 0" class="flex align-items">
                                <div class="col-xs-12">
                                    <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                    <div v-for="(file, idx) in previewAttachments" :key="idx" class="flexSpaceBetween">
                                        <div>
                                            <span>{{ idx + 1 }}.</span>
                                            <span>
                                                <i class="fa fa-file-image-o" aria-hidden="true"></i>
                                            </span>
                                            <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-else>
                                <div>-</div>
                            </template>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryTeamInfoModal' + question.uuid"
            class="modal default-modal"
            :class="{ 'dark-modal': isNightMode }"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryTeamInfoModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryTeamInfoModal' + question.uuid + '-title'" class="modal-title">View {{ previewTeam.name }}</h2>
                    </div>
                    <div class="modal-body">
                        <fieldset>
                            <legend class="flexOnly align-items">
                                <i class="fas fa-users marginRight8" aria-hidden="true"></i>
                                <b>Team Members</b>
                            </legend>
                            <ol class="margin0">
                                <template v-for="(student, idx) in previewTeam.members" :key="idx">
                                    <li class="marginBottom10 fs-18px">{{ student.displayName }}</li>
                                </template>
                            </ol>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryVotersInfoModal' + question.uuid"
            class="modal default-modal"
            :class="{ 'dark-modal': isNightMode }"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryVotersInfoModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryVotersInfoModal' + question.uuid + '-title'" class="modal-title">Voted By</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12">
                            <template v-if="testObj.others.applicationType == 'individual'">
                                <ol v-for="(student, idx) in previewVoters" :key="idx">
                                    <li>{{ student.displayName }}</li>
                                </ol>
                            </template>
                            <template v-if="testObj.others.applicationType == 'team'">
                                <ol v-for="(team, idx) in previewVoters" :key="idx">
                                    <li>{{ team.name }}</li>
                                </ol>
                            </template>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryQnsModal' + question.uuid"
            class="modal default-modal"
            :class="{ 'dark-modal': isNightMode }"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryQnsModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryQnsModal' + question.uuid + '-title'" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true"></i>View Answer
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 wordBreakWord whiteSpacePreWrap imgMax100" v-html="questionModal"></div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryModelModal' + question.uuid"
            class="modal default-modal"
            :class="{ 'dark-modal': isNightMode }"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryModelModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryModelModal' + question.uuid + '-title'" class="modal-title">
                            <template v-if="previewModel.type == 'team'"> View {{ previewModel.team.name }} </template>
                            <template v-else-if="previewModel.type == 'student'"> View {{ previewModel.student.fullname }} </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <fieldset>
                            <legend class="flexOnly align-items">
                                <i class="fas fa-comments marginRight8" aria-hidden="true"></i>
                                <b>Elaboration</b>
                            </legend>
                            <span v-if="previewModel.comment" class="wordBreakWord whiteSpacePreWrap">{{ previewModel.comment }}</span>
                            <p v-else>-</p>
                        </fieldset>

                        <fieldset class="marginTop20">
                            <legend class="flexOnly align-items">
                                <i class="fas fa-paperclip marginRight8" aria-hidden="true"></i>
                                <b>Attachment(s)</b>
                            </legend>
                            <template v-if="previewModel.attachments && previewModel.attachments.length != 0">
                                <div v-for="(file, idx) in previewModel.attachments" :key="idx" class="flex">
                                    <span class="marginRight5">{{ idx + 1 }}.</span>
                                    <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{ file.filename }}</a>
                                </div>
                            </template>
                            <template v-else> - </template>
                        </fieldset>

                        <fieldset class="marginTop20">
                            <legend class="flexOnly align-items">
                                <i class="fas fa-users marginRight8" aria-hidden="true"></i>
                                <b>Team Members</b>
                            </legend>
                            <ol class="margin0">
                                <template v-for="(student, idx) in previewModel.team.members" :key="idx">
                                    <li class="marginBottom10 fs-18px">{{ student.displayName }}</li>
                                </template>
                            </ol>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryCommentModal' + question.uuid"
            class="modal default-modal"
            :class="{ 'dark-modal': isNightMode }"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryCommentModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryCommentModal' + question.uuid + '-title'" class="modal-title">
                            <template v-if="previewModel.type == 'team'"> View {{ previewModel.team.name }} </template>
                            <template v-else-if="previewModel.type == 'student'"> View {{ previewModel.student.fullname }} </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-comments marginRight8" aria-hidden="true"></i>
                                <b>Comment</b>
                            </div>
                            <div v-if="previewModel.comment">
                                <span class="wordBreakWord whiteSpacePreWrap">{{ previewModel.comment }}</span>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div v-if="previewModel.type == 'team'" class="marginTop20">
                            <div class="flexOnly marginBottom10 align-items">
                                <i class="fas fa-users marginRight8" aria-hidden="true"></i>
                                <b>Team Members</b>
                            </div>

                            <ol class="margin0">
                                <template v-for="(student, idx) in previewModel.team.members" :key="idx">
                                    <li>{{ student.displayName }}</li>
                                </template>
                            </ol>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryCommentsModal' + question.uuid"
            class="modal default-modal"
            :class="{ 'dark-modal': isNightMode }"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryCommentsModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryCommentsModal' + question.uuid + '-title'" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true"></i>View Comments
                        </h2>
                    </div>
                    <div class="modal-body">
                        <template v-for="(comment, comment_idx) in previewComments">
                            <div v-if="comment.team || comment.student" :key="'comment_' + comment_idx" :class="{ marginTop20: comment_idx > 0 }">
                                <span class="tableStatusTag whiteSpaceNowrap tableStatusTag-default">
                                    <label>
                                        {{ testObj.others.applicationType ? comment.team.name : comment.student.fullname }}
                                    </label>
                                </span>
                                <p class="wordBreakWord whiteSpacePreWrap marginTop10" v-html="filterProfanity(comment.comment)" />
                            </div>
                        </template>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click.prevent="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../../../../../../../../../../../../components/auth/auth';
export default {
    props: ['testObj', 'question', 'showRealtimeToggle', 'selectedColumn'],
    data() {
        let auth = new KrAuth();
        return {
            previewTeam: {},
            previewVoters: {},
            previewAttachments: [],
            questionModal: '',
            previewModel: {
                type: 'team',
                student: {},
                team: {},
                comment: {},
                attachments: [],
            },
            previewComments: [],
            auth: auth,
            readOnlyLabel: 'You have read-only access',
            wordLength: 0,
        };
    },
    computed: {
        isAllVotingToggled() {
            for (var i = 0; i < this.question.answers.length; i++) {
                if (this.question.answers[i].isSelected === 1 || !this.question.answers[i].isSelected) {
                    return false;
                }
            }
            return true;
        },
        isAllGoodAnswerToggled() {
            for (var i = 0; i < this.question.answers.length; i++) {
                if (!this.question.answers[i].isGoodAnswer) {
                    return false;
                }
            }
            return true;
        },
        canShowGrades() {
            if (this.$route.name == 'activities.tests.questions') {
                return false;
            }
            return true;
        },
        isNightMode() {
            return (
                this.question.type == 'openended' &&
                ['ongoing', 'ended', 'released'].includes(this.question.eGallery.status) &&
                this.$route.name == 'activities.tests.questions'
            );
        },
    },
    created() {
        var that = this;

        Vue.nextTick(function () {
            $('#eGalleryTeamInfoModal .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryAttachmentsModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryTeamInfoModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryVotersInfoModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryQnsModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryCommentModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
            $('#eGalleryCommentsModal' + that.question.uuid + ' .modal-dialog').draggable({
                handle: '.modal-header',
            });
        });
    },
    mounted() {
        if (!this.$refs.textarea) {
            return;
        }
        for (var i = 0; i < this.$refs.textarea.length; i++) {
            if (this.$refs.textarea) {
                var text = this.$refs.textarea[i].value;
                this.wordLength = this.computeNoOfWords(text);

                $(this.$refs.textarea[i])
                    .siblings('.limitText')
                    .text(this.wordLength + ' Words  / Max 1000 Words');

                if (this.wordLength > 1000) {
                    $(this.$refs.textarea[i]).siblings('.limitText').addClass('fontRed');
                } else {
                    $(this.$refs.textarea[i]).siblings('.limitText').removeClass('fontRed');
                }

                this.$refs.textarea[i].style.minHeight = this.$refs.textarea[i].scrollHeight + 'px';
            }
        }
    },
    methods: {
        toggleIsGoodAnswer(answer) {
            axios
                .post(`tests/${this.testObj.uuid}/discussions/is-good-answer`, {
                    answerUuid: answer.uuid,
                })
                .then(function (response) {});

            if (answer.isGoodAnswer) {
                answer.isSelected = true;
                //Vue.set(answer, 'isSelected', true);
            }
        },
        saveGrade(answer_idx) {
            var that = this;
            axios
                .post(
                    'tests/' + this.testObj.uuid + '/discussions/save-grade',
                    {
                        answerUuid: this.question.answers[answer_idx].uuid,
                        grade: this.question.answers[answer_idx]._applicationGrade,
                    },
                    { _internal: { hasLoadingBar: false } }
                )
                .then(function (response) {
                    that.question.answers[answer_idx].applicationGrade = that.question.answers[answer_idx]._applicationGrade;
                    that.question.answers[answer_idx]._gradeSaved = true;
                })
                .catch(function (errors) {});
        },
        saveNarrativeFeedback(answer_idx) {
            var that = this;
            axios
                .post(
                    'tests/' + this.testObj.uuid + '/discussions/save-narrative-feedback',
                    {
                        answerUuid: this.question.answers[answer_idx].uuid,
                        narrativeFeedback: this.question.answers[answer_idx]._narrativeFeedback,
                    },
                    { _internal: { hasLoadingBar: false } }
                )
                .then(function (response) {
                    that.question.answers[answer_idx].narrativeFeedback = that.question.answers[answer_idx]._narrativeFeedback;
                    that.$set(that.question.answers[answer_idx], '_narrativeSaved', true);
                })
                .catch(function (errors) {});
        },
        changePoints(event, answer_idx) {
            let value = parseFloat(event.target.value);
            if (isNaN(value)) {
                return;
            }
            let rem = value % 1;
            if (rem != 0) {
                value = Math.floor(value);
                if (rem > 0.5) {
                    value += 0.75;
                } else if (rem > 0.25) {
                    value += 0.5;
                } else {
                    value += 0.25;
                }
            }
            if (value <= this.question.score && value >= 0) {
                this.question.answers[answer_idx]._applicationGrade = value;
            } else {
                $(event.target).val(this.question.answers[answer_idx].applicationGrade);
            }
        },
        computeNoOfWords(str) {
            return str ? str.split(/[^\s]+/).length - 1 : 0;
        },
        changeNarrativeFeedback(event, answer_idx) {
            let text = event.target.value;
            this.wordLength = this.computeNoOfWords(text);

            if (this.wordLength > 1000) {
                $(event.target).val(this.question.answers[answer_idx].narrativeFeedback);
            } else {
                this.question.answers[answer_idx]._narrativeFeedback = text;
            }

            $('#' + answer_idx + '-count-words').text(this.wordLength + ' Words / Max 1000 Words');

            if (this.wordLength > 1000) {
                $('#' + answer_idx + '-count-words').addClass('fontRed');
            } else {
                $('#' + answer_idx + '-count-words').removeClass('fontRed');
            }
        },
        quantityUp() {
            if (parseInt(this.question.eGallery.rankingValue) == this.question.answers.length) {
                return false;
            }
            this.question.eGallery.rankingValue += 1;
        },
        quantityDown() {
            if (parseInt(this.question.eGallery.rankingValue) == 1) {
                return false;
            }
            this.question.eGallery.rankingValue -= 1;
        },
        toggleAllVoting(event) {
            if (this.isAllVotingToggled) {
                for (var i = 0; i < this.question.answers.length; i++) {
                    if (this.question.answers[i].isGoodAnswer) {
                        this.question.answers[i].isSelected = true;
                        //Vue.set(this.question.answers[i], 'isSelected', true);
                    } else {
                        this.question.answers[i].isSelected = false;
                        //Vue.set(this.question.answers[i], 'isSelected', false);
                    }
                }
            } else {
                for (var i = 0; i < this.question.answers.length; i++) {
                    this.question.answers[i].isSelected = true;
                    //Vue.set(this.question.answers[i], 'isSelected', true);
                }
            }
        },
        toggleAllGoodAnswer(event) {
            if (this.isAllGoodAnswerToggled) {
                for (var i = 0; i < this.question.answers.length; i++) {
                    this.question.answers[i].isGoodAnswer = false;
                    //Vue.set(this.question.answers[i], 'isGoodAnswer', false);
                }
            } else {
                for (var i = 0; i < this.question.answers.length; i++) {
                    this.question.answers[i].isGoodAnswer = true;
                    //Vue.set(this.question.answers[i], 'isGoodAnswer', true);
                }
            }
        },
        /*Whole comments*/
        openCommentsModal(answer) {
            var that = this;
            this.previewComments = answer.comments;
            if (this.previewComments.length == 0) {
                return;
            }
            $('#eGalleryCommentsModal' + this.question.uuid).modal('show');

            $('#eGalleryCommentsModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        openTeamInfoModal(team) {
            var that = this;
            this.previewTeam = team;
            $('#eGalleryTeamInfoModal' + this.question.uuid).modal('show');

            $('#eGalleryTeamInfoModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        openAttachmentsModal(attachments) {
            var that = this;
            this.previewAttachments = attachments;
            $('#eGalleryAttachmentsModal' + this.question.uuid).modal('show');
            $('#eGalleryAttachmentsModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewAttachments = [];
            });
        },
        openVotersInfoModal(voters) {
            var that = this;
            this.previewVoters = voters;
            $('#eGalleryVotersInfoModal' + this.question.uuid).modal('show');

            $('#eGalleryVotersInfoModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewVoters = {};
            });
        },
        openModelInfoModal(answer, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = answer.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = answer.student;
            }
            this.previewModel.comment = answer.comment;
            this.previewModel.attachments = answer.attachments ? answer.attachments : [];
            $('#eGalleryModelModal' + this.question.uuid).modal('show');

            $('#eGalleryModelModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                };
            });
        },
        openCommentInfoModal(comment, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = comment.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = comment.student;
            }
            this.previewModel.comment = comment.comment;
            $('#eGalleryCommentModal' + this.question.uuid).modal('show');

            $('#eGalleryCommentModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                };
            });
        },
        highlightAnswerKeywords(answer = '') {
            answer = answer ? answer : '';
            var res = answer;
            var tags = this.question.tags ? this.question.tags : [];
            for (var i = 0; i < tags.length; i++) {
                res = res.replace(new RegExp(this.escapeRegExp(tags[i]), 'gi'), `<span class="highlight marginBottom10">${tags[i]}</span>`);
            }
            return this.filterProfanity(res);
            //return res;
        },
        openQuestionModal(str) {
            this.questionModal = str;
            $('#eGalleryQnsModal' + this.question.uuid).modal('show');
        },
        truncate(str, characters = 100) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        percentVoted(answer_idx) {
            let present = this.question.answers.length;
            if (present == 0) {
                return null;
            }
            return Math.round((this.question.answers[answer_idx].votes.length / present) * 100);
        },
        generateToolTip(isSelected) {
            if (isSelected) {
                return 'Remove from eGallery Walk';
            } else {
                return 'Select for eGallery Walk';
            }
        },
        textareaResize($event) {
            if (!this.$refs.textarea) {
                return;
            }

            $event.target.style.height = 'auto';
            $event.target.style.height = $event.target.scrollHeight + 'px';
        },
    },
};
</script>

<style lang="scss" scoped>
.mini-panel .panel-heading {
    font-size: 14px;
}

.mini-panel .panel-heading .link {
    text-transform: capitalize;
}

.mini-panel .btnSections {
    width: 30.6%;
    margin-right: 4%;
}

.mini-panel .btnSections:nth-child(3n) {
    margin-right: 0;
}

.btnSections {
    margin-top: 10px;
}

.btnSections:nth-child(-n + 3) {
    margin-top: 0;
}

.discussion .panel,
body.test .mini-panel.panel {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

.mini-panel.panel {
    margin-bottom: 0;
}

.btnDiscussionTeams {
    margin-top: 10px;
}

.btnDiscussionTeams:first-child {
    margin-top: 0;
}

.mini-table .numberInput input {
    width: 100px;
}

.staticDetails {
    font-size: 14px;
}

.panel-content {
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.panel-content:last-child {
    border-bottom: 0;
}

.width230px {
    width: 230px;
}

.modal_comments {
    margin-bottom: 20px;

    &:last-child,
    &:only-child {
        margin-bottom: 0;
    }
}

.gridDiscussion {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
}

.limitText {
    font-size: 14px;
    margin-top: 10px;
    float: right;
    color: #717171;

    &.fontRed {
        color: #ca3333;
    }
}
</style>
