<template>
    <div v-if="!form.isHidden(name)" :id="(id ? id : name) + '-div'" :class="[cls, form.errors.has(name) ? 'has-error' : '']" :style="style">
        <slot name="label">
            <template v-if="hasLabel">
                <label v-if="!isHtmlEditor" class="control-label" :for="id ? id : name">
                    <span v-html="display" />
                    <template v-if="required">
                        <span class="visible-hidden">Required</span>
                        <span class="important" aria-hidden="true">*</span>
                    </template>

                    <template v-if="tooltip">
                        <i class="fa fa-question-circle" aria-hidden="true" data-toggle="tooltip" data-placement="top" :data-original-title="tooltip" />
                    </template>
                </label>
            </template>
        </slot>
        <div>
            <slot name="input">
                <template v-if="isHtmlEditor">
                    <kr-editor v-model="value" :options="htmlEditorOptions" :display="display" :displayRequired="required" />
                </template>
                <template v-else-if="type == 'email'">
                    <!--class="fills"-->
                    <div :class="{ 'input-group': hasAddOnSlot }">
                        <input
                            :id="id ? id : name"
                            v-model="value"
                            :name="nameLabel"
                            :disabled="form.isDisabled(name) ? true : undefined"
                            type="email"
                            :maxlength="maxlength"
                            :placeholder="placeholder"
                            :autofocus="autofocus"
                            :autocomplete="autocomplete"
                            :aria-label="ariaLabel"
                            :aria-describedby="describedby"
                            class="form-control"
                            :class="{ isTabbed: isTabbed, name }"
                            @keydown.ctrl.meta.space="onIOSEmojiPanelOpen($event)"
                            @keydown="form.errors.clear($event.target.name)"
                            @keyup.tab="isTabbed = true"
                            @keydown.tab="isTabbed = false"
                            @click="isTabbed = false"
                            @keyup="keyup"
                            @paste="
                                form.errors.clear($event.target.name);
                                keyup;
                            "
                            @blur="blur"
                        />
                        <!--<span v-show="form.errors.has(name)"><i style="color:#b40020;" class="fa fa-times-circle"></i></span>-->
                        <!--<span><i style="color:#47a647;" class="fa fa-check-circle"></i></span>-->
                        <div v-if="hasAddOnSlot" class="input-group-addon">
                            <slot name="addon" />
                        </div>
                    </div>
                </template>
                <template v-else-if="type == 'text'">
                    <!--class="fills"-->
                    <div :class="{ 'input-group': hasAddOnSlot }">
                        <input
                            :id="id ? id : name"
                            v-model="value"
                            :name="nameLabel"
                            :disabled="form.isDisabled(name) ? true : undefined"
                            type="text"
                            :maxlength="maxlength"
                            :placeholder="placeholder"
                            :autofocus="autofocus"
                            :autocomplete="autocomplete"
                            :aria-label="ariaLabel"
                            :aria-describedby="describedby"
                            class="form-control"
                            :class="[{ isTabbed: isTabbed }, name]"
                            @keydown.ctrl.meta.space="onIOSEmojiPanelOpen($event)"
                            @keydown="form.errors.clear($event.target.name)"
                            @keyup.tab="isTabbed = true"
                            @keydown.tab="isTabbed = false"
                            @click="isTabbed = false"
                            @keyup="keyup"
                            @paste="
                                form.errors.clear($event.target.name);
                                keyup;
                            "
                            @blur="blur"
                        />
                        <!--<span v-show="form.errors.has(name)"><i style="color:#b40020;" class="fa fa-times-circle"></i></span>-->
                        <!--<span><i style="color:#47a647;" class="fa fa-check-circle"></i></span>-->
                        <div v-if="hasAddOnSlot" class="input-group-addon">
                            <slot name="addon" />
                        </div>
                    </div>
                </template>
                <template v-else-if="type == 'password'">
                    <template v-if="newPasswordField">
                        <div :class="{ 'position-relative': !hasAddOnSlot }">
                            <input
                                :id="id ? id : name"
                                v-model="value"
                                :name="nameLabel"
                                :disabled="form.isDisabled(name)"
                                :type="showPassword ? 'text' : 'password'"
                                :maxlength="maxlength"
                                :placeholder="placeholder"
                                :aria-label="ariaLabel"
                                :aria-describedby="describedby"
                                class="form-control"
                                :class="name"
                                @keydown.ctrl.meta.space="onIOSEmojiPanelOpen($event)"
                                @change="emit"
                                @keydown="form.errors.clear($event.target.name)"
                                @keyup="keyup"
                                @paste="
                                    form.errors.clear($event.target.name);
                                    keyup;
                                "
                                @blur="
                                    blur();
                                    showToolTip = false;
                                "
                                @focus="showToolTip = true"
                            />

                            <button class="btn position-absolute passwordVisible" @click="showPassword = !showPassword">
                                <i
                                    :class="{
                                        'fa-regular fa-eye-slash': showPassword,
                                        'fa-regular fa-eye': !showPassword,
                                    }"
                                    :aria-label="showPassword ? 'visibility off' : 'visibility'"
                                ></i>
                            </button>

                            <div v-if="tooltipMessage && showToolTip" class="input-tooltip" aria-live="assertive">
                                <span class="input-tooltip-arrow"></span>
                                {{ tooltipMessage }}
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div :class="{ 'input-group': hasAddOnSlot }">
                            <input
                                :id="id ? id : name"
                                v-model="value"
                                :name="nameLabel"
                                :disabled="form.isDisabled(name)"
                                type="password"
                                :maxlength="maxlength"
                                :placeholder="placeholder"
                                class="form-control"
                                :class="name"
                                @change="emit"
                                @keydown.ctrl.meta.space="onIOSEmojiPanelOpen($event)"
                                @keydown="form.errors.clear($event.target.name)"
                                @keyup="keyup"
                                @paste="
                                    form.errors.clear($event.target.name);
                                    keyup;
                                "
                                @blur="blur"
                            />
                            <div v-if="hasAddOnSlot" class="input-group-addon">
                                <slot name="addon" />
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else-if="type == 'text-area'">
                    <textarea
                        :id="id ? id : name"
                        v-model="value"
                        :name="nameLabel"
                        :disabled="form.isDisabled(name) ? true : undefined"
                        style="min-height: 60px"
                        :maxlength="maxlength"
                        :rows="rows"
                        :placeholder="placeholder"
                        class="form-control"
                        @keydown.ctrl.meta.space="onIOSEmojiPanelOpen($event)"
                        @keydown="form.errors.clear($event.target.name)"
                        @keyup="keyup"
                        @paste="
                            form.errors.clear($event.target.name);
                            keyup;
                        "
                        @blur="blur"
                    />
                </template>
                <template v-else-if="type == 'number'">
                    <div class="numberGroup" :class="{ 'input-group flex': hasAddOnSlot }">
                        <label v-if="hasAddOnSlot" class="visible-hidden" :for="id ? id : name">
                            <template v-if="slotContent == 'Days' && value < 2"> Day </template>
                            <template v-else-if="slotContent == 'Hours' && value < 2"> Hour </template>
                            <template v-else-if="slotContent == 'Mins'">
                                <template v-if="value > 1"> Minutes </template>
                                <template v-else> Minute </template>
                            </template>
                            <template v-else>
                                {{ slotContent }}
                            </template>
                        </label>
                        <div class="numberInput">
                            <input
                                :id="id ? id : name"
                                v-model="value"
                                :class="[name, { fixedWidthNumber: dateWidth }]"
                                :name="nameLabel"
                                :disabled="form.isDisabled(name) || disabled ? true : undefined"
                                type="number"
                                :placeholder="placeholder"
                                class="form-control"
                                @keydown.ctrl.meta.space="onIOSEmojiPanelOpen($event)"
                                @keydown="
                                    isNumber($event);
                                    form.errors.clear($event.target.name);
                                "
                                @blur="blur"
                            />

                            <span class="quantity-nav">
                                <div
                                    class="quantity-button quantity-up"
                                    :class="{
                                        disabled: value == max || form.isDisabled(name) || disabled,
                                    }"
                                    @click="quantityUp"
                                >
                                    <i class="fas fa-plus" aria-hidden="true" />
                                </div>
                                <div
                                    class="quantity-button quantity-down"
                                    :class="{
                                        disabled: value == min || form.isDisabled(name) || disabled,
                                    }"
                                    @click="quantityDown"
                                >
                                    <i class="fas fa-minus" aria-hidden="true" />
                                </div>
                            </span>
                        </div>
                        <div v-if="hasAddOnSlot" class="input-group-addon widthAuto flex align-items">
                            <slot name="addon"> sadasdadasdasdasd </slot>
                        </div>

                        <div v-if="hasAddOnSlot" class="visible-hidden" role="status" aria-live="assertive" aria-atomic="true">
                            {{ value }}
                            <template v-if="slotContent == 'Days' && value < 2"> Day </template>
                            <template v-else-if="slotContent == 'Hours' && value < 2"> Hour </template>
                            <template v-else-if="slotContent == 'Mins'">
                                <template v-if="value > 1"> Minutes </template>
                                <template v-else> Minute </template>
                            </template>
                            <template v-else>
                                {{ slotContent }}
                            </template>
                        </div>
                    </div>
                </template>
                <div v-if="maxlength" :style="'color:' + charColor + ';font-size:14px;padding-top:5px'">
                    <span style="float: left; color: #717171; font-weight: bold"> Min {{ maxlength }} Characters </span>
                    <span style="float: right"> {{ value.length }} / {{ maxlength }} Characters </span>
                </div>
                <slot v-if="showError" name="error-display">
                    <span v-if="(form.errors.has(name) && hideHelpBlockIfNoErrors) || !hideHelpBlockIfNoErrors" class="help-block">
                        <strong v-show="form.errors.has(name)">{{ form.errors.get(name) }}</strong>
                        <!--<span><i class="fa fa-times-circle" style="color: #b40020;font-size:30px;margin-right:10px;"></i></span><strong>{{ form.errors.get(name) }}</strong>-->
                    </span>
                </slot>
                <!--
        <slot name="error-display">
	        <span class="help-block correct-display" v-show="form.errors.has(name)">
	            <span><i class="fa fa-check-circle" style="color: #47a647;;font-size:30px;margin-right:10px;"></i></span><span>You will receive access to InteDashboard until <br><strong>18 SEPTEMBER 2018</strong></spant
	        </span>
        </slot>
        -->
            </slot>
        </div>
    </div>
</template>
<script>
export default {
    compatConfig: {
        MODE: 3,
    },
    props: ['modelValue', 'display', 'form', 'name', 'options', 'ariaLabel', 'disabled', 'tooltipMessage'],
    emits: ['keyup', 'blur', 'changed', 'update:modelValue'],
    data() {
        return {
            min: null,
            max: null,
            decimal: -1,
            maxlength: null,
            minlength: null,
            charColor: '#6b9c5d',
            rows: null,
            placeholder: null,
            hasLabel: true,
            style: '',
            type: 'text',
            cls: 'form-group',
            tooltip: null,
            showError: true,
            isHtmlEditor: false,
            htmlEditorOptions: {},
            hideHelpBlockIfNoErrors: false,
            id: '',
            nameLabel: '',
            arialLabel: '',
            autofocus: '',
            autocomplete: '',
            dateWidth: false,
            isTabbed: false,
            required: false,
            slotContent: '',
            newPasswordField: false,
            showPassword: false,
            showToolTip: false,
        };
    },
    computed: {
        hasAddOnSlot() {
            return !!this.$slots['addon'];
        },
        value: {
            get() {
                //return this.modelValue;
                return _.get(this.form.model, this.name, '');
            },
            set(value) {
                if (this.type == 'number') {
                    if (this.decimal == -1) {
                        value = Number(value);
                    } else {
                        value = Number(value).toFixed(this.decimal);
                    }
                    if (value < this.min) {
                        value = this.min;
                    }
                    if (value > this.max) {
                        value = this.max;
                    }
                    $('[id="' + this.id + '"]').val(value);
                }
                if (_.has(this.form.model, this.name)) {
                    _.set(this.form.model, this.name, value);
                    this.$emit('changed');
                }
            },
        },
    },
    watch: {
        /*'form.model': {
            deep: true,
            handler(value, oldValue) {
                this.value = _.get(this.form.model, this.name, '');
            },
        },*/
        name: {
            handler(value, oldValue) {
                this.init();
            },
        },
        value: {
            handler(value, oldValue) {
                if (this.minlength) {
                    if (this.value.length < this.minlength) {
                        this.charColor = '#ff0202';
                    } else {
                        this.charColor = '#6b9c5d';
                    }
                }
                if (this.isHtmlEditor) {
                    this.form.errors.clear(name);
                }
            },
        },
        options: {
            handler() {
                if (this.type == 'number') {
                    var max = _.get(this, 'options.max', 999999999999999);
                    this.max = max;
                    var min = _.get(this, 'options.min', -99999999999999);
                    this.min = min;
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (this.hasAddOnSlot) {
            this.slotContent = _.get(this.$slots, 'addon.0.text', '').trim();
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.showError = _.get(this, 'options.showError', true);
            if (_.get(this, 'options.type', false)) {
                this.type = _.get(this, 'options.type', false);
                if (this.type == 'number') {
                    var max = _.get(this, 'options.max', 99999999);
                    this.max = max;
                    var min = _.get(this, 'options.min', -99999999);
                    this.min = min;
                    var decimal = _.get(this, 'options.decimal', -1);
                    this.decimal = decimal;
                } else if (this.type == 'text' || this.type == 'password') {
                    var maxlength = _.get(this, 'options.maxlength', false);
                    if (maxlength) {
                        this.maxlength = maxlength;
                    }
                } else if (this.type == 'text-area') {
                    var rows = _.get(this, 'options.rows', false);
                    if (rows) {
                        this.rows = rows;
                    }
                    var maxlength = _.get(this, 'options.maxlength', false);
                    if (maxlength) {
                        this.maxlength = maxlength;
                    }
                    var minlength = _.get(this, 'options.minlength', false);
                    if (minlength) {
                        this.minlength = minlength;
                    }
                    if (this.minlength) {
                        if (this.value.length < this.minlength) {
                            this.charColor = '#ff0202';
                        }
                    }
                }
            } else {
                //default type text
                var maxlength = _.get(this, 'options.maxlength', false);
                if (maxlength) {
                    this.maxlength = maxlength;
                }
            }
            //nameLabel
            this.id = _.get(this, 'options.id', this.name);
            this.nameLabel = _.get(this, 'options.name', this.name);
            //placeholder
            var placeholder = _.get(this, 'options.placeholder', false);
            if (placeholder) {
                this.placeholder = placeholder;
            }
            //label
            var label = _.get(this, 'options.hasLabel', true);
            this.hasLabel = label;

            //placeholder
            var cls = _.get(this, 'options.cls', null);
            if (cls != null) {
                this.cls = cls;
            }
            //style
            var style = _.get(this, 'options.style', null);
            if (style != null) {
                this.style = style;
            }
            //autofocus
            var autofocus = _.get(this, 'options.autofocus', null);
            if (autofocus != null) {
                this.autofocus = autofocus;
            }
            //autocomplete
            var autocomplete = _.get(this, 'options.autocomplete', null);
            if (autocomplete != null) {
                this.autocomplete = autocomplete;
            }
            //htmlEditor
            var isHtmlEditor = _.get(this, 'options.htmlEditor', false);
            this.isHtmlEditor = isHtmlEditor;
            if (isHtmlEditor != null) {
                this.htmlEditorOptions = _.get(this, 'options.htmlEditorOptions', {});
            }
            //tooltip
            var tooltip = _.get(this, 'options.tooltip', null);
            if (tooltip != null) {
                this.tooltip = tooltip;
                Vue.nextTick(function () {
                    $('[data-toggle="tooltip"]').tooltip();
                });
            }

            var hideHelpBlockIfNoErrors = _.get(this, 'options.hideHelpBlockIfNoErrors', null);
            if (hideHelpBlockIfNoErrors != null) {
                this.hideHelpBlockIfNoErrors = hideHelpBlockIfNoErrors;
            }

            //label
            var dateWidth = _.get(this, 'options.dateWidth', false);
            this.dateWidth = dateWidth;

            //required
            var required = _.get(this, 'options.required', false);
            if (required) {
                this.required = required;
            }

            //newPasswordField
            var newPasswordField = _.get(this, 'options.newPasswordField', false);
            if (newPasswordField) {
                this.newPasswordField = newPasswordField;
            }

            //describeby
            var describedby = _.get(this, 'options.describedby', false);
            if (describedby) {
                this.describedby = describedby;
            }
        },
        quantityUp() {
            if (this.form.isDisabled(this.name)) {
                return;
            }
            var oldValue = parseInt(this.value ? this.value : 0);
            if (oldValue >= parseInt(this.max)) {
                var newVal = oldValue;
            } else {
                var newVal = oldValue + 1;
            }
            //console.log('quantityUp ' + newVal )
            //console.log('this is max value ' + this.max +' & this is min value ' + this.min )
            this.value = newVal;
        },
        quantityDown() {
            if (this.form.isDisabled(this.name)) {
                return;
            }
            var oldValue = parseInt(this.value ? this.value : 0);
            if (oldValue <= this.min) {
                var newVal = oldValue;
            } else {
                var newVal = oldValue - 1;
            }
            //console.log('quantityDown ' + newVal )
            this.value = newVal;
        },
        isNumber(event) {
            var keysAllowed = [
                '0',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                'Backspace',
                'Delete',
                //".",
                'ArrowUp',
                'ArrowDown',
                'Tab',
            ];
            if (this.decimal > 0) {
                keysAllowed.push('.');
            }
            const keyPressed = event.key;
            // console.log(keyPressed);
            if (!keysAllowed.includes(keyPressed)) {
                event.preventDefault();
            }
        },
        validateNumber() {
            this.value = this.value.replace(/[^0-9]|^0+(?!$)/g, '');
        },
        validateEmail() {
            const re =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let a = re.test(String(this.value).toLowerCase());

            if (!a) {
                this.form.errors.append(this.name, ['This should be a valid email']);
            }
        },
        keyup(event) {
            this.value = $('[id="' + this.id + '"]').val();
            var RE_EMOJI = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
            this.value = this.value.replace(RE_EMOJI, '');
            this.$emit('keyup', event);
        },
        blur(event) {
            this.value = $('[id="' + this.id + '"]').val();
            this.$emit('blur', event);
        },
        onIOSEmojiPanelOpen(e) {
            e.preventDefault();
        },
    },
};
</script>

<style lang="scss" scoped>
.numberInput {
    display: inline-block;
}

.input-group-addon,
.input-group-addon:last-child {
    border-radius: 3px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border: 1px solid #717171;
    border-left: 0;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
}

.input-group input {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.iratPoints .numberInput {
    width: 110px;
}

.timerTopBar #durationHours-div .input-group,
.timerTopBar #durationMinutes-div .input-group {
    margin-bottom: 0;
    width: 122px;
}

input.iratDurationMinutes,
input.iratDurationHours,
input.tratDurationMinutes,
input.tratDurationHours,
input.applicationDurationMinutes,
input.applicationDurationHours,
input.evaluationDurationMinutes,
input.evaluationDurationHours {
    width: 64px;
}

input.iratDurationDays,
input.tratDurationDays,
input.applicationDurationDays,
input.evaluationDurationDays {
    width: 64px;
}

.fixedWidthNumber {
    width: 64px !important;
}

.form-control[type='number'] {
    outline: 0;
    outline-offset: 0;
    box-shadow: none;
}

.numberGroup {
    display: inline-flex;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        border-radius: 3px;
    }
}

.input-tooltip {
    position: absolute;
    bottom: 100%;
    right: -1%;
    background-color: rgba(73, 80, 87, 0.9);
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    min-width: 10em;
    max-width: 30em;
    font-size: 14px;
    width: 15em;
    white-space: normal;
    font-size: 12px;
}

.input-tooltip-arrow {
    position: absolute;
    top: 100%;
    right: 10%;
    transform: translateX(-50%);
    border: 5px solid transparent;
    border-top-color: #495057;
    pointer-events: none;
}

@media only screen and (max-width: 360px) {
    .input-tooltip {
        min-width: 100%;
        max-width: inherit;
    }
}
</style>
